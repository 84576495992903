import "./App.css";

import BusinessForm from "./pages/BusinessForm";
import CampaignForm from "./pages/CampaignForm";
import AddCustomer from "./pages/AddCustomer";
import ValidateCoupon from "./pages/ValidateCoupon";
import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";
import PaymentCancellation from "./pages/PaymentCancellation";
import { AuthContextProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import CampaignHome from "./pages/CampaignHome";
import AddAgents from "./pages/AddAgents";
import customTheme from "./design/customTheme";
import Terms from "./pages/Terms";
import BusinessList from "./pages/BusinessList";
import Privacy from "./pages/Privacy";
import { LanguageProvider } from "./contexts/LanguageContext";
import PendingApproval from "./pages/PendingApproval";
// import StripeConnectTest from "./pages/StripeConnectTest";
import Refresh from "./pages/Refresh";
import Return from "./pages/Return";
import OrganizationForm from "./pages/OrganizationForm";
import OrganizationHome from "./pages/OrganizationHome";
import ResourceProtectedRoute from "./components/ResourceProtectedRoute";
import ProtectedCampaignRoute from "./components/ProtectedCampaignRoute";
import ProtectedOrganizationRoute from "./components/ProtectedOrganizationRoute";
import Register from "./pages/Register";
import EmailVerification from "./pages/EmailVerification";
import ExternalBusinessForm from "./pages/ExternalBusinessForm";
import BusinessTypeSelection from "./pages/BusinessTypeSelection";
import SubmissionSuccess from "./pages/SubmissionSuccess";
import BusinessInstructions from "./pages/BusinessInstructions";
import AddBusinessSuccess from "./pages/AddBusinessSuccess";
import AddBusinessSuccessInternal from "./pages/AddBusinessSucccessInternal";
import AddBusinessSuccessExternal from "./pages/AddBusinessSuccessExternal";
import ApprovalForm from "./pages/ApprovalForm";
// import BusinessCardExample from "./components/BusinessCardExample";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <ChakraProvider theme={customTheme}>
          <AuthContextProvider>
            <div className="App">
              <Routes>
                {/* <Route
                  path="/stripe-connect-test"
                  element={<StripeConnectTest />}
                /> */}
                <Route path="/pending-approval" element={<PendingApproval />} />
                <Route path="/" element={<SignIn />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route
                  path="/refresh/:connectedAccountId"
                  element={<Refresh />}
                />
                <Route
                  path="/return/:connectedAccountId"
                  element={<Return />}
                />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/campaign/:campaignId"
                  element={
                    <ProtectedRoute>
                      <ProtectedCampaignRoute>
                        <CampaignHome />
                      </ProtectedCampaignRoute>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-agents/:campaignId"
                  element={
                    <ProtectedRoute>
                      <AddAgents />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-campaign/:organizationId?"
                  element={
                    <ProtectedRoute>
                      <CampaignForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit-campaign/:campaignId"
                  element={
                    <ProtectedRoute>
                      <CampaignForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-organization"
                  element={<OrganizationForm />}
                />
                <Route
                  path="/organization/:organizationId"
                  element={
                    <ProtectedRoute>
                      <ProtectedOrganizationRoute>
                        <OrganizationHome />
                      </ProtectedOrganizationRoute>
                    </ProtectedRoute>
                  }
                />

                <Route path="/payment/success" element={<PaymentSuccess />} />
                <Route path="/payment/failure" element={<PaymentFailure />} />
                <Route
                  path="/payment/cancellation"
                  element={<PaymentCancellation />}
                />
                <Route
                  path="/support/:campaignId?/:agentId?"
                  element={<AddCustomer />}
                />
                <Route
                  path="/businesses/:campaignId"
                  element={<BusinessList />}
                />
                <Route
                  path="/add-business"
                  element={
                    <ProtectedRoute>
                      <BusinessForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit-business"
                  element={
                    <ProtectedRoute>
                      <BusinessForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/validate/:businessCode?"
                  element={<ValidateCoupon />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route
                  path="/new-business/:businessId"
                  element={<ExternalBusinessForm />}
                />
                <Route
                  path="/business-type-selection/:campaignId"
                  element={
                    <ProtectedRoute>
                      <BusinessTypeSelection />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/submission-success"
                  element={<SubmissionSuccess />}
                />
                <Route
                  path="/business-instructions/:businessId"
                  element={<BusinessInstructions />}
                />
                <Route
                  path="/add-business-success/:businessId"
                  element={<AddBusinessSuccess />}
                />

                <Route
                  path="/add-business-success-internal/:businessId"
                  element={
                    <ProtectedRoute>
                      <AddBusinessSuccessInternal />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-business-success-external/:businessId"
                  element={<AddBusinessSuccessExternal />}
                />
                <Route
                  path="/approve-business/:businessId"
                  element={<ApprovalForm />}
                />

                {/* <Route
                  path="/business-card-example"
                  element={<BusinessCardExample />}
                /> */}
              </Routes>
            </div>
          </AuthContextProvider>
        </ChakraProvider>
      </Router>
    </LanguageProvider>
  );
}

export default App;
