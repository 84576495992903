import React from "react";
import { Box } from "@chakra-ui/react";
import AddBusinessSuccess from "./AddBusinessSuccess";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";

const AddBusinessSuccessExternal = () => {
  const { businessId } = useParams();
  const { translate } = useLanguage();

  return (
    <>
      <Navbar external={true} />
      <AddBusinessSuccess
        businessId={businessId}
        title={translate("businessSuccess.title")}
        subtitle={translate("businessSuccess.subtitle")}
        nextSteps={[
          translate("businessSuccess.nextSteps.step1"),
          translate("businessSuccess.nextSteps.step2"),
          translate("businessSuccess.nextSteps.step3"),
        ]}
        viewInstructionsText={translate("businessSuccess.viewInstructions")}
        homeText={translate("common.home")}
      />
    </>
  );
};

export default AddBusinessSuccessExternal;
