import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Heading,
  useToast,
  SimpleGrid,
  useColorModeValue,
  Text,
  Flex,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";
import {
  FaBuilding,
  FaPlus,
  FaChevronRight,
  FaExclamationCircle,
} from "react-icons/fa";

const Home = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = UserAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          setLoading(true);
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          // Fetch organizations
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const orgsWithApprovalStatus = await Promise.all(
              (userData.organizations || []).map(async (organizationId) => {
                const orgDoc = await getDoc(
                  doc(db, "organizations", organizationId)
                );
                const orgData = orgDoc.data();
                return {
                  organizationId,
                  approved: orgData?.approved ?? false,
                  name: orgData?.name || "Unnamed Organization",
                };
              })
            );
            setOrganizations(orgsWithApprovalStatus);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast({
            title: "Error",
            description: "Failed to load user data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [user, toast]);

  const handleCreateOrganization = () => {
    navigate("/create-organization");
  };

  const handleOrganizationClick = (organizationId) => {
    navigate(`/organization/${organizationId}`);
  };

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)"
        bg="primaryBackground"
        pt={{ base: "80px", md: "60px" }}
        px={{ base: 4, sm: 6, md: 10 }}
      >
        <VStack
          spacing={{ base: 6, md: 8 }}
          maxWidth="1200px"
          margin="0 auto"
          py={{ base: 6, md: 8 }}
          width="100%"
        >
          <Heading
            as="h1"
            size={{ base: "xl", md: "lg" }}
            color="heading"
            alignSelf={{ base: "center", md: "flex-start" }}
            textAlign={{ base: "center", md: "left" }}
            px={{ base: 2, md: 0 }}
          >
            Welcome, {user?.firstName}!
          </Heading>

          {/* Organizations Section */}
          <Box
            width="100%"
            bg="white"
            p={{ base: 4, sm: 6, md: 8 }}
            borderRadius="xl"
            boxShadow="md"
          >
            {organizations.length > 0 ? (
              <>
                <Flex
                  justify="space-between"
                  align={{ base: "stretch", sm: "center" }}
                  direction={{ base: "column", sm: "row" }}
                  gap={{ base: 4, sm: 0 }}
                  mb={{ base: 6, md: 8 }}
                >
                  <HStack spacing={3}>
                    <Icon as={FaBuilding} boxSize={6} color="black" />
                    <Heading as="h2" size="md" color="heading">
                      Your Organizations
                    </Heading>
                  </HStack>
                  <Button
                    leftIcon={<FaPlus />}
                    variant="primary"
                    onClick={handleCreateOrganization}
                    size={{ base: "sm", md: "md" }}
                    width={{ base: "100%", sm: "auto" }}
                  >
                    Create Organization
                  </Button>
                </Flex>

                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacing={{ base: 4, md: 6 }}
                  width="100%"
                >
                  {organizations.map((org) => (
                    <Box
                      key={org.organizationId}
                      p={{ base: 4, md: 5 }}
                      borderRadius="lg"
                      border="1px"
                      borderColor="gray.200"
                      boxShadow="sm"
                      cursor="pointer"
                      onClick={() =>
                        handleOrganizationClick(org.organizationId)
                      }
                      _hover={{
                        transform: "translateY(-2px)",
                        transition: "all 0.2s",
                        boxShadow: "md",
                      }}
                      position="relative"
                      role="group"
                    >
                      <Flex justify="space-between" align="center" pr={6}>
                        <Heading size="sm" color="heading" fontWeight="medium">
                          {org.name}
                        </Heading>
                        {!org.approved && (
                          <Icon
                            as={FaExclamationCircle}
                            color="orange.500"
                            boxSize={{ base: 4, md: 5 }}
                            title="Pending Approval"
                          />
                        )}
                      </Flex>
                      <Icon
                        as={FaChevronRight}
                        position="absolute"
                        top="50%"
                        right={4}
                        transform="translateY(-50%)"
                        color="gray.400"
                        boxSize={{ base: 4, md: 5 }}
                        _groupHover={{ color: "gray.600" }}
                      />
                    </Box>
                  ))}
                </SimpleGrid>
              </>
            ) : (
              <Box p={{ base: 6, md: 8 }} textAlign="center">
                <VStack spacing={{ base: 4, md: 6 }}>
                  <Icon
                    as={FaBuilding}
                    boxSize={{ base: 10, md: 12 }}
                    color="black"
                  />
                  <Heading size="md" color="heading">
                    No Organizations Yet
                  </Heading>
                  <Text
                    color="gray.600"
                    maxW="md"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    To start a fundraising campaign, you can either create an
                    own organization or wait for an invitation to join an
                    existing campaign.
                  </Text>
                  <Button
                    leftIcon={<FaPlus />}
                    variant="primary"
                    onClick={handleCreateOrganization}
                    size={{ base: "sm", md: "md" }}
                  >
                    Create Organization
                  </Button>
                </VStack>
              </Box>
            )}
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default Home;
