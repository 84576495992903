import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardBody,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";
import axios from "axios";
import { apiUrl } from "../config";
import { useToast } from "@chakra-ui/react";

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const BusinessTypeSelection = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { user } = UserAuth();
  const toast = useToast();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);

  const handleSelection = (isSelfEntry) => {
    if (isSelfEntry) {
      // Navigate to existing business form
      navigate(`/add-business?campaignId=${campaignId}`);
    } else {
      // Open email modal
      setIsEmailModalOpen(true);
    }
  };

  const handleEmailSubmit = async (values, { setSubmitting }) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const response = await axios.post(
        `${apiUrl}/generate-business-link`,
        {
          email: values.email,
          campaignId: campaignId,
          type: "new",
          userId: user.uid,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      // Store the generated URL
      setGeneratedLink(response.data.url);

      toast({
        title: "Success",
        description: "Email sent to business successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.error ||
          "Failed to generate business link. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink);
    toast({
      title: "Copied",
      description: "Link copied to clipboard",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const EmailModal = () => (
    <Modal
      isOpen={isEmailModalOpen}
      onClose={() => {
        setIsEmailModalOpen(false);
        setGeneratedLink(null);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        {!generatedLink ? (
          // Initial email form
          <>
            <ModalHeader>Enter Business Email</ModalHeader>
            <ModalCloseButton />
            <Formik
              initialValues={{ email: "" }}
              validationSchema={emailValidationSchema}
              onSubmit={handleEmailSubmit}
            >
              {(props) => (
                <Form>
                  <ModalBody>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel>Business Email</FormLabel>
                          <Input
                            {...field}
                            placeholder="Enter business email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="ghost"
                      mr={3}
                      onClick={() => setIsEmailModalOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      isLoading={props.isSubmitting}
                    >
                      Send
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          // Success state
          <>
            <ModalHeader>Link Generated Successfully</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Text>
                  An email has been sent to the business with instructions to
                  complete their submission.
                </Text>
                <Box
                  p={3}
                  bg="gray.50"
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor="gray.200"
                >
                  <Text fontSize="sm" wordBreak="break-all">
                    {generatedLink}
                  </Text>
                </Box>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => {
                  setIsEmailModalOpen(false);
                  setGeneratedLink(null);
                  navigate(`/campaign/${campaignId}`);
                }}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleCopyLink}>
                Copy Link
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          align="center"
          maxWidth="800px"
          margin="0 auto"
          pt={8}
          spacing={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Add a Business
          </Heading>

          <Box
            width="100%"
            bg="secondaryBackground"
            p={8}
            borderRadius="xl"
            boxShadow="md"
          >
            <VStack spacing={6} width="100%">
              <Text textAlign="center" fontSize="lg">
                Choose how to add the business.
              </Text>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} width="100%">
                <Card
                  as="button"
                  onClick={() => handleSelection(true)}
                  cursor="pointer"
                  bg="white"
                  borderColor="gray.200"
                  borderWidth="2px"
                  _hover={{ bg: "#f8ffec", borderColor: "primaryHighlight" }}
                >
                  <CardBody>
                    <VStack spacing={3}>
                      <Text fontSize="xl" fontWeight="bold">
                        Enter Details Now
                      </Text>
                      <Text color="gray.600">
                        I have all the business information and will enter it
                        myself.
                      </Text>
                    </VStack>
                  </CardBody>
                </Card>
                <Card
                  as="button"
                  onClick={() => handleSelection(false)}
                  cursor="pointer"
                  bg="white"
                  borderColor="gray.200"
                  borderWidth="2px"
                  _hover={{ bg: "#f8ffec", borderColor: "primaryHighlight" }}
                >
                  <CardBody>
                    <VStack spacing={3}>
                      <Text fontSize="xl" fontWeight="bold">
                        Send to Business
                      </Text>
                      <Text color="gray.600">
                        Send a link to the business to submit their information.
                      </Text>
                    </VStack>
                  </CardBody>
                </Card>
              </SimpleGrid>
            </VStack>
          </Box>
        </VStack>
      </Box>
      <EmailModal />
    </>
  );
};

export default BusinessTypeSelection;
