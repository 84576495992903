import React from "react";
import { Box, Flex, Image, Button, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import logo from "../assets/logos/logo.png";
import { useLanguage } from "../contexts/LanguageContext";

const Navbar = ({ external = false }) => {
  const navigate = useNavigate();
  const logoSize = useBreakpointValue({ base: "150px", md: "150px" });
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const { language, setLanguage } = useLanguage();

  const handleLogoClick = () => {
    navigate("/home");
  };

  return (
    <Box as="nav" bg="white" boxShadow="sm" py={5} width="100%">
      <Flex
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={4}
      >
        <Button
          onClick={handleLogoClick}
          variant="unstyled"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={logo} alt="Logo" w={logoSize} h="auto" />
        </Button>
        {external ? (
          <Flex align="center">
            <Box
              as="button"
              py={2}
              px={2}
              onClick={() => setLanguage("en")}
              color={language === "en" ? "black" : "gray.500"}
              fontWeight={language === "en" ? "bold" : "normal"}
            >
              EN
            </Box>
            <Box px={0.5} color="gray.300">
              |
            </Box>
            <Box
              as="button"
              py={2}
              px={2}
              onClick={() => setLanguage("fr")}
              color={language === "fr" ? "black" : "gray.500"}
              fontWeight={language === "fr" ? "bold" : "normal"}
            >
              FR
            </Box>
          </Flex>
        ) : (
          <LogoutButton />
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;
