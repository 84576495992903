import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Spinner,
  SimpleGrid,
  Text,
  Heading,
  Button,
  Flex,
} from "@chakra-ui/react";
import { db, storage } from "../firebase";
import { useLanguage } from "../contexts/LanguageContext";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import BusinessCard from "../components/BusinessCard";
import Navbar from "../components/Navbar";
import { FaShoppingCart, FaRegClock } from "react-icons/fa";

const BusinessList = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [campaignSnap, setCampaignSnap] = useState(null);
  const { language, setLanguage, translate } = useLanguage();

  useEffect(() => {
    const fetchBusinessesAndCampaign = async () => {
      try {
        // Fetch campaign name
        const campaignRef = doc(db, "campaigns", campaignId);
        const campaignSnap = await getDoc(campaignRef);
        if (campaignSnap.exists()) {
          setCampaignName(campaignSnap.data().name);
          setCampaignSnap(campaignSnap);
        } else {
          setError("Campaign not found.");
          return;
        }

        // Fetch businesses - add approved filter
        const businessesRef = collection(db, "businesses");
        const q = query(
          businessesRef,
          where("campaignId", "==", campaignId),

          where("approved", "==", true)
        );
        const querySnapshot = await getDocs(q);
        const businessList = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const businessData = doc.data();
            const businessId = doc.id;
            let logoUrl = null;

            try {
              const logoRef = ref(storage, `business-logos/${businessId}`);
              logoUrl = await getDownloadURL(logoRef);
            } catch (error) {
              console.error(
                `Error fetching logo for business ${businessId}:`,
                error
              );
            }

            return {
              id: businessId,
              ...businessData,
              logoUrl,
            };
          })
        );
        setBusinesses(businessList);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessesAndCampaign();
  }, [campaignId]);

  // Helper function to get the localized text with fallback
  const getLocalizedText = (field) => {
    if (typeof field === "object" && field !== null) {
      return field[language] || field.en || "";
    }
    return field || "";
  };

  if (loading) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="primaryBackground"
    >
      <Navbar external={true} />

      <Box
        maxWidth="1200px"
        width="100%"
        margin="0 auto"
        px={{ base: 4, md: 6, lg: 8 }}
        pb={{ base: 6, md: 8, lg: 10 }}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems={{ base: "center", md: "flex-start" }}
          mb={{ base: 6, md: 8 }}
          mt={{ base: 16, md: 20 }}
        >
          <Box textAlign={{ base: "center", md: "left" }}>
            <Heading as="h1" size={{ base: "xl", md: "xl" }} mb={1}>
              {translate("businessList.heading")}
            </Heading>

            <Text fontSize={{ base: "xl", md: "xl", lg: "2xl" }} mb={4}>
              {getLocalizedText(campaignName)}
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems={{ base: "center", md: "flex-end" }}
          >
            <Button
              onClick={() => navigate(`/support/${campaignId}`)}
              variant="primary"
              size="md"
              leftIcon={<FaShoppingCart />}
              mt={{ base: 4, md: 0 }}
            >
              {translate("businessList.goToPurchase")}
            </Button>
            {campaignSnap?.data()?.redemptionStartDate &&
              campaignSnap?.data()?.redemptionEndDate && (
                <Box w="100%" bg="gray.50" borderColor="gray.200" mt={3}>
                  <Flex justify="center" align="center" gap={2}>
                    <FaRegClock color="gray.600" size={20} />
                    <Text fontSize={{ base: "sm", md: "md" }} color="gray.600">
                      {translate("businessList.validFrom")}{" "}
                      <Text as="span" color="black" fontWeight="semibold">
                        {new Date(
                          campaignSnap.data().redemptionStartDate?.toDate?.() ||
                            campaignSnap.data().redemptionStartDate
                        ).toLocaleDateString(
                          language === "fr" ? "fr-CA" : "en-US",
                          {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </Text>{" "}
                      {translate("businessList.to")}{" "}
                      <Text as="span" color="black" fontWeight="semibold">
                        {new Date(
                          campaignSnap.data().redemptionEndDate?.toDate?.() ||
                            campaignSnap.data().redemptionEndDate
                        ).toLocaleDateString(
                          language === "fr" ? "fr-CA" : "en-US",
                          {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                  </Flex>
                </Box>
              )}
          </Box>
        </Box>

        <SimpleGrid
          columns={{ base: 1, sm: 2, xl: 3 }}
          spacing={{ base: 6, md: 8, lg: 10 }}
          px={{ base: 2, md: 4 }}
          mt={{ base: 10, md: 12 }}
          mx="auto"
          justifyItems="center"
          maxWidth="100%"
        >
          {businesses.map((business) => (
            <BusinessCard
              key={business.id}
              business={{
                name: getLocalizedText(business.name),
                category: getLocalizedText(business.category),
                description: getLocalizedText(business.description),
                imageUrl: business.logoUrl,
                websiteUrl: business.websiteUrl,
                googleProfileUrl: business.googleProfileUrl,
                coupon: {
                  title: getLocalizedText(business.offerTitle),
                  description: getLocalizedText(business.offerDescription),
                  termsAndExclusions: getLocalizedText(
                    business.termsAndExclusions
                  ),
                  maxValue: business.maxValue,
                },
              }}
              translations={{
                couponDetails: translate("businessList.couponDetails"),
                visitWebsite: translate("businessList.website"),
                googleProfile: translate("businessList.googleProfile"),
                termsAndExclusions: translate(
                  "businessList.termsAndExclusions"
                ),
                maxValue: translate("businessList.maxValue"),
              }}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default BusinessList;
