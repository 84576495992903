import React from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import Navbar from "../components/Navbar";
import {
  VStack,
  Heading,
  Text,
  Box,
  Image,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import logo from "../assets/logos/logo.png";
import A4Page from "../components/A4Page";
import { UserAuth } from "../contexts/AuthContext";
import { useLanguage } from "../contexts/LanguageContext";

const BusinessInstructions = () => {
  const { user } = UserAuth();
  const { businessId } = useParams();
  const { translate } = useLanguage();
  const validationUrl = `https://app.coupal.io/validate/${businessId}`;

  const content = (
    <VStack align="stretch" spacing={4}>
      <Box>
        <Image src={logo} alt="Coupal Logo" height="50px" objectFit="contain" />
      </Box>

      <Heading size="xl" textAlign="left">
        {translate("businessInstructions.heading")}
      </Heading>
      <Text textAlign="left">
        {translate("businessInstructions.introduction")}
      </Text>

      <OrderedList spacing={2} pl={5} textAlign="left">
        <ListItem>{translate("businessInstructions.steps.step1")}</ListItem>
        <ListItem>{translate("businessInstructions.steps.step2")}</ListItem>
        <Text fontSize="xs">
          {translate("businessInstructions.steps.note")}
        </Text>
        <ListItem>{translate("businessInstructions.steps.step3")}</ListItem>
        <ListItem>{translate("businessInstructions.steps.step4")}</ListItem>
      </OrderedList>

      <Text textAlign="left">
        {translate("businessInstructions.businessId")}: {businessId}
      </Text>

      <Box alignSelf="center" p={4}>
        <QRCode value={validationUrl} size={256} level="H" />
      </Box>
      <Text textAlign="center">
        {translate("businessInstructions.qrInstructions")}
      </Text>
      <Text textAlign="center">{validationUrl}</Text>

      <Text textAlign="left" fontWeight="bold">
        {translate("businessInstructions.printRecommendation")}
      </Text>
      <Text textAlign="left">{translate("businessInstructions.support")}</Text>
    </VStack>
  );

  return (
    <>
      <Navbar external={true} />
      <A4Page content={content} />
    </>
  );
};

export default BusinessInstructions;
