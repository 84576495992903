import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Link,
  Text,
} from "@chakra-ui/react";
import { useLanguage } from "../contexts/LanguageContext";

const translations = {
  terms: {
    title: {
      en: "Terms and Conditions",
      fr: "Termes et Conditions",
    },
    body: {
      prefix: {
        en: "By proceeding, you acknowledge that you have read and agree to our ",
        fr: "En continuant, vous reconnaissez avoir lu et accepté nos ",
      },
      link: {
        en: "terms and conditions",
        fr: "termes et conditions",
      },
      suffix: {
        en: " for participating businesses.",
        fr: " pour les entreprises participantes.",
      },
      urls: {
        en: "https://docs.coupal.io/terms/businesses?lang=en",
        fr: "https://docs.coupal.io/terms/businesses?lang=fr",
      },
    },
    cancel: {
      en: "Cancel",
      fr: "Annuler",
    },
    agree: {
      en: "Confirm",
      fr: "Confirmer",
    },
  },
};

const TermsAlertDialog = ({
  isOpen,
  onClose,
  onProceed,
  cancelRef,
  useTranslation = true,
}) => {
  const { language } = useLanguage();

  const getText = (key) => {
    return useTranslation
      ? translations.terms[key][language]
      : translations.terms[key].en;
  };

  const getBodyText = (part) => {
    return useTranslation
      ? translations.terms.body[part][language]
      : translations.terms.body[part].en;
  };

  const getTermsUrl = () => {
    return useTranslation
      ? translations.terms.body.urls[language]
      : translations.terms.body.urls.en;
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {getText("title")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text display="inline">
              {getBodyText("prefix")}
              <Link
                href={getTermsUrl()}
                color="blue.500"
                textDecoration="underline"
                isExternal
              >
                {getBodyText("link")}
              </Link>
              {getBodyText("suffix")}
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {getText("cancel")}
            </Button>
            <Button variant="primary" onClick={onProceed} ml={3}>
              {getText("agree")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TermsAlertDialog;
