import React, { useState, useEffect, useRef } from "react";
import ReactCardFlip from "react-card-flip";
import {
  Box,
  VStack,
  Text,
  IconButton,
  Heading,
  Image,
  Badge,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { FaStore, FaGlobe, FaGoogle } from "react-icons/fa";

import { FaCircleArrowRight, FaCircleArrowLeft } from "react-icons/fa6";

import { useBreakpointValue } from "@chakra-ui/react";

// Card configuration
const cardDimensions = {
  minW: { base: "280px", sm: "320px", md: "360px" },
  maxW: "sm",
  height: "450px",
  borderRadius: "xl",
  bg: "white",
  boxShadow: "xl",
  border: "1px",
  borderColor: "gray.300",
};

const BusinessCard = ({ business, translations }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const iconSize = useBreakpointValue({ base: "40px", md: "60px" });
  const [titleFontSize, setTitleFontSize] = useState({
    base: "2xl",
    md: "3xl",
  });
  const titleRef = useRef(null);

  useEffect(() => {
    const checkTitleHeight = () => {
      if (titleRef.current) {
        const lineHeight = parseInt(
          window.getComputedStyle(titleRef.current).lineHeight
        );
        const elementHeight = titleRef.current.offsetHeight;
        const numberOfLines = elementHeight / lineHeight;

        if (numberOfLines > 2) {
          setTitleFontSize({ base: "xl", md: "2xl" });
        } else {
          setTitleFontSize({ base: "2xl", md: "3xl" });
        }
      }
    };

    checkTitleHeight();
    // Add resize listener
    window.addEventListener("resize", checkTitleHeight);
    return () => window.removeEventListener("resize", checkTitleHeight);
  }, [business.coupon.title]);

  const handleFlip = (e) => {
    e.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  // Front of the card
  const CardFront = () => (
    <Box {...cardDimensions} overflow="hidden" position="relative">
      {/* Hero Image Section */}
      <Box height="175px">
        {business.imageUrl ? (
          <Image
            src={business.imageUrl}
            alt={business.name}
            w="100%"
            h="100%"
            objectFit="cover"
          />
        ) : (
          <Flex
            w="100%"
            h="100%"
            bg="gray.100"
            justifyContent="center"
            alignItems="center"
          >
            <FaStore size={iconSize} color="gray.400" />
          </Flex>
        )}
      </Box>

      {/* Content Section */}
      <VStack
        align="stretch"
        spacing={2}
        pt={3}
        px={6}
        pb={6}
        height="calc(100% - 200px)"
      >
        {/* Business Info */}
        <VStack align="start" spacing={1} mb={2}>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="semibold"
            color="gray.800"
          >
            {business.name}
          </Text>
          <Badge
            colorScheme="gray"
            fontSize="xs"
            borderRadius="full"
            textTransform="none"
            fontWeight="normal"
            px={2}
            py={1}
          >
            {business.category}
          </Badge>
        </VStack>

        {/* Offer Details */}
        <VStack align="start" spacing={0}>
          <Heading
            ref={titleRef}
            fontSize={titleFontSize}
            fontWeight="bold"
            color="gray.800"
            lineHeight="1.1"
            textAlign="left"
            w="100%"
          >
            {business.coupon.title}
          </Heading>

          <Text
            fontSize={{ base: "sm", md: "sm" }}
            color="gray.600"
            lineHeight="tall"
            textAlign="left"
            pt={1}
          >
            {business.coupon.description}
          </Text>
        </VStack>
      </VStack>

      {/* Maximum Value - Only show if maxValue exists and is not 0 */}
      {business.coupon.maxValue > 0 && (
        <Flex
          position="absolute"
          bottom="4"
          left="6"
          alignItems="center"
          gap={2}
        >
          <Text
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="semibold"
            color="gray.800"
          >
            ${business.coupon.maxValue}
          </Text>
          <Text fontSize="xs" color="gray.600">
            ({translations?.maxValue || "Max Value"})
          </Text>
        </Flex>
      )}
    </Box>
  );

  // Back of the card
  const CardBack = () => (
    <Box {...cardDimensions} overflow="hidden" position="relative">
      <VStack spacing={6} p={6} height="100%" align="stretch">
        <Heading size="md" alignSelf="flex-start">
          {business.name}
        </Heading>

        <Divider borderColor="gray.300" />

        {/* Terms and Conditions */}
        <VStack align="start" spacing={2} flex={1}>
          <Text fontWeight="bold">
            {translations?.termsAndExclusions || "Terms & Exclusions"}:
          </Text>
          <Text fontSize="sm" color="gray.700" textAlign="left">
            {business.coupon.termsAndExclusions ||
              translations?.noSpecificTerms ||
              "No specific terms"}
          </Text>
        </VStack>

        {/* Contact Information */}
        {(business.websiteUrl || business.googleProfileUrl) && (
          <Flex
            position="absolute"
            bottom={4}
            left={6}
            alignItems="center"
            height="22px"
            gap={2}
          >
            {business.websiteUrl && (
              <IconButton
                icon={<FaGlobe size="22px" />}
                aria-label={translations?.visitWebsite || "Visit Website"}
                onClick={() => window.open(business.websiteUrl, "_blank")}
                variant="ghost"
                size="sm"
                minW="auto"
                height="auto"
                p={0}
              />
            )}
            {business.googleProfileUrl && (
              <IconButton
                icon={<FaGoogle size="22px" />}
                aria-label={translations?.googleProfile || "Google Profile"}
                onClick={() => window.open(business.googleProfileUrl, "_blank")}
                variant="ghost"
                size="sm"
                minW="auto"
                height="auto"
                p={0}
                ml={2}
              />
            )}
          </Flex>
        )}
      </VStack>
    </Box>
  );

  return (
    <div style={{ width: "fit-content", margin: "0 auto" }}>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        {/* Front Side */}
        <Box position="relative">
          <CardFront />
          <Box
            as={FaCircleArrowRight}
            position="absolute"
            bottom={4}
            right={4}
            size="22px"
            color="black"
            cursor="pointer"
            onClick={handleFlip}
            aria-label="Show more info"
          />
        </Box>

        {/* Back Side */}
        <Box position="relative">
          <CardBack />
          <Box
            as={FaCircleArrowLeft}
            position="absolute"
            bottom={4}
            right={4}
            size="22px"
            color="black"
            cursor="pointer"
            onClick={handleFlip}
            aria-label="Show front"
          />
        </Box>
      </ReactCardFlip>
    </div>
  );
};

export default BusinessCard;
