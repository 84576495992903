import React from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Icon,
  Container,
  List,
  ListItem,
  HStack,
} from "@chakra-ui/react";
import { FaCheckCircle, FaArrowRight, FaHome } from "react-icons/fa";
import { UserAuth } from "../contexts/AuthContext";

const AddBusinessSuccess = ({
  title,
  subtitle,
  nextSteps,
  businessId,
  viewInstructionsText = "View Business Instructions",
  homeText = "Home",
}) => {
  const { user } = UserAuth();

  return (
    <Box bg="primaryBackground" minH="calc(100vh - 60px)">
      <Container maxW="container.md" py={20}>
        <VStack align="center" textAlign="center">
          <Icon
            as={FaCheckCircle}
            w={20}
            h={20}
            color="primaryHighlight"
            animation="scale 0.5s ease-in-out"
          />

          <Heading size="xl" color="gray.700">
            {title}
          </Heading>

          <Text fontSize="lg" color="gray.600" mb={8}>
            {subtitle}
          </Text>

          <Box
            bg="secondaryBackground"
            p={6}
            borderRadius="lg"
            shadow="lg"
            w="full"
            textAlign="left"
          >
            <VStack spacing={4} align="stretch">
              <Heading size="md" color="gray.700">
                Next Steps:
              </Heading>
              <List spacing={2}>
                {nextSteps.map((step, index) => (
                  <ListItem key={index}>{`${index + 1}. ${step}`}</ListItem>
                ))}
              </List>
            </VStack>
          </Box>

          <HStack spacing={4} mt={8}>
            <Button
              as={RouterLink}
              to={`/business-instructions/${businessId}`}
              rightIcon={<FaArrowRight />}
              variant="secondary"
              size="md"
            >
              {viewInstructionsText}
            </Button>

            {user && (
              <Button
                as={RouterLink}
                to="/"
                leftIcon={<FaHome />}
                variant="outline"
                size="md"
              >
                {homeText}
              </Button>
            )}
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default AddBusinessSuccess;
