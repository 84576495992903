import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  FormErrorMessage,
  Heading,
  Text,
  Image,
  Flex,
  Link,
  Container,
  HStack,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import logo from "../assets/logos/logo.png";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";
import Navbar from "../components/Navbar"; // Import the Navbar component
import PhoneNumberInput from "../components/PhoneNumberInput";
import { FaArrowRight } from "react-icons/fa";

const AddCustomer = () => {
  const navigate = useNavigate();
  const { campaignId, agentId, lang } = useParams();
  const { language, setLanguage, translate } = useLanguage();
  const [campaigns, setCampaigns] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isCampaignActive, setIsCampaignActive] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const getLocalizedValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return language === "fr" && value.fr ? value.fr : value.en || "";
    }
    return value || "";
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaignsRef = collection(db, "campaigns");
      const querySnapshot = await getDocs(campaignsRef);
      const campaignsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Store the whole name object
      }));
      setCampaigns(campaignsData);
    };

    fetchCampaigns();

    if (campaignId) {
      fetchCampaignDetails(campaignId);
      fetchAgents(campaignId);
    }

    // Set language based on the 'lang' parameter
    if (lang === "fr") {
      setLanguage("fr");
    } else {
      setLanguage("en");
    }
  }, [campaignId, lang, setLanguage]);

  useEffect(() => {
    const fetchData = async () => {
      if (campaignId) {
        await fetchCampaignDetails(campaignId);
        await fetchAgents(campaignId);

        // If agentId is provided, fetch agent details
        if (agentId) {
          const agentDoc = await getDoc(
            doc(db, "campaigns", campaignId, "agents", agentId)
          );
          if (agentDoc.exists()) {
            setSelectedAgent(agentDoc.data());
          }
        }
      }
    };

    fetchData();
  }, [campaignId, agentId]);

  const initialValues = {
    campaignId: campaignId || "",
    agentId: agentId || "",
    name: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    language: language || "en",
    agreeToTerms: false,
  };

  const handleSubmit = async (values, actions) => {
    try {
      await axios
        .post(`${apiUrl}/create-checkout-session`, values)
        .then((response) => {
          if (response.status === 200) {
            window.location = response.data.url;
          } else {
            return Promise.reject(response.data);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });

      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      console.error("Error adding customer:", error);
      actions.setSubmitting(false);
    }
  };

  const fetchCampaignDetails = async (campaignId) => {
    const campaignRef = doc(db, "campaigns", campaignId);
    const campaignSnap = await getDoc(campaignRef);
    if (campaignSnap.exists()) {
      const data = campaignSnap.data();

      // Check if the campaign has ended
      const endDate = data.endDate?.toDate?.() || new Date(data.endDate);
      const isExpired = endDate < new Date();

      setSelectedCampaign({
        id: campaignSnap.id,
        ...data,
      });
      // Set campaign as inactive if it's either manually set to inactive OR if it's expired
      setIsCampaignActive(!isExpired && (data.active ?? false));
    } else {
      setSelectedCampaign(null);
      setIsCampaignActive(false);
    }
  };

  const fetchAgents = async (campaignId) => {
    const agentsRef = collection(db, "campaigns", campaignId, "agents");
    const agentsSnapshot = await getDocs(agentsRef);
    const agentsData = agentsSnapshot.docs.map((doc) => {
      const data = doc.data();
      const fullName = data.lastName
        ? `${data.firstName} ${data.lastName}`
        : data.firstName;
      return {
        id: doc.id,
        name: fullName,
      };
    });
    setAgents(agentsData);
  };

  const validationSchema = Yup.object().shape({
    campaignId: Yup.string().required(
      translate("addCustomer.validationErrors.campaignRequired")
    ),
    agentId: Yup.string().required(
      translate("addCustomer.validationErrors.fundraiserRequired")
    ),
    name: Yup.string().required(
      translate("addCustomer.validationErrors.nameRequired")
    ),
    email: Yup.string()
      .email(translate("addCustomer.validationErrors.invalidEmail"))
      .required(translate("addCustomer.validationErrors.emailRequired")),
    confirmEmail: Yup.string()
      .oneOf(
        [Yup.ref("email"), null],
        translate("addCustomer.validationErrors.emailsMustMatch")
      )
      .required(translate("addCustomer.validationErrors.confirmEmailRequired")),
    phoneNumber: Yup.string().matches(
      /^\d{10}$/,
      translate("addCustomer.validationErrors.invalidPhone")
    ),
    agreeToTerms: Yup.boolean()
      .oneOf(
        [true],
        translate("addCustomer.validationErrors.agreeToTermsRequired")
      )
      .required(translate("addCustomer.validationErrors.agreeToTermsRequired")),
  });

  return (
    <Box minH="100vh" bg="primaryBackground">
      <Navbar external={true} />
      <Container
        mt={{ base: 2, md: 4 }}
        pb={{ base: 12, md: 20 }}
        maxW="container.xl"
        centerContent
        pt={{ base: 4, md: 8 }}
        px={{ base: 4, sm: 6, md: 8 }}
      >
        {/* Campaign Info Box */}
        {selectedCampaign ? (
          <Box
            width="100%"
            maxW="600px"
            p={{ base: 4, md: 6 }}
            mb={{ base: 3, md: 4 }}
          >
            <VStack
              spacing={{ base: 2, md: 3 }}
              align={{ base: "center", md: "start" }}
            >
              <Heading
                as="h1"
                size={{ base: "md", md: "lg" }}
                color="black"
                textAlign={{ base: "center", md: "left" }}
                width="100%"
              >
                {getLocalizedValue(selectedCampaign.name)}
              </Heading>
              <Text
                fontSize={{ base: "sm", md: "md" }}
                color="gray.600"
                textAlign={{ base: "center", md: "left" }}
                width="100%"
              >
                {getLocalizedValue(selectedCampaign.description)}
              </Text>

              <Box
                as="hr"
                w="100%"
                borderColor="gray.200"
                my={{ base: 1, md: 2 }}
              />

              <Flex
                width="100%"
                justify="space-between"
                align="center"
                direction={{ base: "column", sm: "row" }}
                gap={{ base: 3, sm: 0 }}
              >
                <Box>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color="black"
                    mb={1}
                  >
                    {translate("addCustomer.purchaseToSupport")}
                  </Text>
                  <Text
                    fontSize={{ base: "xl", md: "2xl" }}
                    fontWeight="bold"
                    color="black"
                    textAlign={{ base: "center", sm: "left" }}
                  >
                    ${selectedCampaign.couponBookPrice}
                  </Text>
                </Box>
                <Button
                  onClick={() => {
                    if (selectedCampaign?.offeringURL) {
                      window.open(selectedCampaign.offeringURL, "_blank");
                    } else {
                      navigate(`/businesses/${selectedCampaign.id}`);
                    }
                  }}
                  variant="secondary"
                  size="sm"
                  width={{ base: "100%", sm: "auto" }}
                  rightIcon={<FaArrowRight />}
                >
                  {translate("addCustomer.viewCoupons")}
                </Button>
              </Flex>
            </VStack>
          </Box>
        ) : (
          <Heading
            as="h1"
            size={{ base: "md", md: "lg" }}
            color="black"
            textAlign={{ base: "center", md: "left" }}
            mb={4}
            width="100%"
            maxW="600px"
          >
            {translate("addCustomer.heading")}
          </Heading>
        )}

        {/* Main Form Box */}
        <Box
          width="100%"
          maxW="600px"
          bg="secondaryBackground"
          p={{ base: 4, sm: 6, md: 8 }}
          borderRadius="xl"
          boxShadow="md"
        >
          {selectedCampaign && !isCampaignActive ? (
            <VStack spacing={4} align="center">
              <Text
                color="red.500"
                fontWeight="bold"
                fontSize={{ base: "sm", md: "md" }}
                textAlign="center"
              >
                {translate("addCustomer.campaignInactive")}
              </Text>
              <Button
                variant="secondary"
                size={{ base: "sm", md: "md" }}
                onClick={() => window.location.reload()}
              >
                {translate("addCustomer.backButton")}
              </Button>
            </VStack>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <VStack spacing={{ base: 4, md: 5 }} width="100%">
                    {/* Form Fields */}
                    {!campaignId && (
                      <Field name="campaignId">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.campaignId && form.touched.campaignId
                            }
                            isRequired
                          >
                            <FormLabel fontSize={{ base: "sm", md: "md" }}>
                              {translate("addCustomer.campaign")}
                            </FormLabel>
                            <Select
                              {...field}
                              size={{ base: "sm", md: "md" }}
                              placeholder={translate(
                                "addCustomer.selectCampaign"
                              )}
                              onChange={(e) => {
                                field.onChange(e);
                                fetchCampaignDetails(e.target.value);
                                fetchAgents(e.target.value);
                              }}
                            >
                              {campaigns.map((campaign) => (
                                <option key={campaign.id} value={campaign.id}>
                                  {getLocalizedValue(campaign.name)}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage
                              fontSize={{ base: "xs", md: "sm" }}
                            >
                              {form.errors.campaignId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    )}

                    {!agentId && props.values.campaignId && (
                      <Field name="agentId">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.agentId && form.touched.agentId
                            }
                            isRequired
                          >
                            <FormLabel fontSize="sm">
                              {translate("addCustomer.fundraiser")}
                            </FormLabel>
                            <Select
                              {...field}
                              placeholder={translate(
                                "addCustomer.selectFundraiser"
                              )}
                            >
                              {agents.map((agent) => (
                                <option key={agent.id} value={agent.id}>
                                  {agent.name}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.agentId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    )}

                    {/* Customer Information Fields */}
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel fontSize="sm">
                            {translate("addCustomer.name")}
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={translate(
                              "addCustomer.namePlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel fontSize="sm">
                            {translate("addCustomer.email")}
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={translate(
                              "addCustomer.emailPlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="confirmEmail">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.confirmEmail &&
                            form.touched.confirmEmail
                          }
                        >
                          <FormLabel fontSize="sm">
                            {translate("addCustomer.confirmEmail")}
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={translate(
                              "addCustomer.confirmEmailPlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.confirmEmail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="phoneNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                        >
                          <FormLabel fontSize="sm">
                            {translate("addCustomer.phoneNumber")}
                          </FormLabel>
                          <PhoneNumberInput field={field} />
                          <FormErrorMessage>
                            {form.errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="agreeToTerms">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.agreeToTerms &&
                            form.touched.agreeToTerms
                          }
                        >
                          <Flex alignItems="flex-start">
                            <input
                              type="checkbox"
                              {...field}
                              style={{ marginRight: "8px", marginTop: "4px" }}
                            />
                            <FormLabel fontSize="sm" mb={0}>
                              {translate("addCustomer.agreeToTerms")}{" "}
                              <Link
                                as={RouterLink}
                                to={`https://docs.coupal.io/terms/purchasers?lang=${language}`}
                                color="blue.500"
                                isExternal
                              >
                                {translate("addCustomer.termsAndConditions")}
                              </Link>{" "}
                              {translate("addCustomer.and")}{" "}
                              <Link
                                as={RouterLink}
                                to={`https://docs.coupal.io/privacy/purchasers?lang=${language}`}
                                color="blue.500"
                                isExternal
                              >
                                {translate("addCustomer.privacyPolicy")}
                              </Link>
                            </FormLabel>
                          </Flex>
                          <FormErrorMessage>
                            {form.errors.agreeToTerms}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {/* Submit Button */}
                    <Button
                      variant="primary"
                      type="submit"
                      isLoading={props.isSubmitting}
                      width="100%"
                      size={{ base: "sm", md: "md" }}
                    >
                      {translate("addCustomer.proceedButton")}
                    </Button>

                    {/* Stripe Logo */}
                  </VStack>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AddCustomer;
