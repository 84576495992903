import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { AuthorizationService } from "../services/authorizationService";
import { Flex, Spinner } from "@chakra-ui/react";

const ResourceProtectedRoute = ({
  children,
  resourceType,
  resourceId,
  fallbackPath = "/home",
}) => {
  const { user } = UserAuth();
  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    const checkAccess = async () => {
      if (!user || !resourceId) {
        setHasAccess(false);
        return;
      }

      let accessGranted = false;
      if (resourceType === "campaign") {
        accessGranted = await AuthorizationService.canAccessCampaign(
          user.uid,
          resourceId
        );
      } else if (resourceType === "organization") {
        accessGranted = await AuthorizationService.canAccessOrganization(
          user.uid,
          resourceId
        );
      }

      setHasAccess(accessGranted);
    };

    checkAccess();
  }, [user, resourceType, resourceId]);

  if (hasAccess === null) {
    return (
      <Flex minH="100vh" justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return hasAccess ? children : <Navigate to={fallbackPath} />;
};

export default ResourceProtectedRoute;
