import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Textarea,
  useToast,
  HStack,
  Stack,
  FormHelperText,
  Text,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Checkbox,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiUrl } from "../config";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import FormStepper from "../components/FormStepper";
import PhoneNumberInput from "../components/PhoneNumberInput";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const steps = [
  { title: "Contact" },
  { title: "Campaign Details" },
  { title: "Coupon Book" },
];

const validationSchemas = [
  // Step 1: Contact Info
  Yup.object().shape({
    contactName: Yup.string().required("Contact name is required"),
    contactEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    contactPhone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  }),
  // Step 2: Campaign Details
  Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required("Campaign name (English) is required"),
      fr: Yup.string(),
    }),
    description: Yup.object().shape({
      en: Yup.string()
        .max(1000, "Description must be at most 1000 characters")
        .required("Description (English) is required"),
      fr: Yup.string().max(1000, "Description must be at most 1000 characters"),
    }),
    endDate: Yup.date()
      .required("End date is required")
      .min(new Date(), "End date must be in the future"),
  }),
  // Step 3: Coupon Book
  Yup.object().shape({
    couponBookPrice: Yup.number()
      .positive("Price must be positive")
      .required("Coupon book price is required"),
    offeringURL: Yup.string().url("Invalid URL").nullable(),
    redemptionStartDate: Yup.date()
      .required("Redemption start date is required")
      .min(new Date(), "Start date must be in the future"),
    redemptionEndDate: Yup.date()
      .required("Redemption end date is required")
      .min(Yup.ref("redemptionStartDate"), "End date must be after start date")
      .test(
        "min-after-campaign-end",
        "Redemption end date must be at least 1 month after campaign end date",
        function (value) {
          const campaignEnd = new Date(this.parent.endDate);
          const minEndDate = new Date(campaignEnd);
          minEndDate.setMonth(minEndDate.getMonth() + 1);
          return value >= minEndDate;
        }
      ),
  }),
];

const CampaignForm = () => {
  const { campaignId, organizationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState({
    name: { en: "", fr: "" },
    description: { en: "", fr: "" },
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    couponBookPrice: "",
    offeringURL: "",
    endDate: "",
    redemptionStartDate: "",
    redemptionEndDate: "",
  });
  const { user } = UserAuth();

  const isEditMode = !!campaignId;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [hasConfirmedDateChange, setHasConfirmedDateChange] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(null);
  const cancelRef = React.useRef();

  useEffect(() => {
    const fetchCampaign = async () => {
      if (isEditMode) {
        try {
          const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));

          if (campaignDoc.exists()) {
            const campaignData = campaignDoc.data();

            // Format dates from Firestore Timestamp to YYYY-MM-DD
            const formatDate = (timestamp) => {
              if (!timestamp) return "";
              // Check if it's a Firestore Timestamp
              if (timestamp?.toDate) {
                return timestamp.toDate().toISOString().split("T")[0];
              }
              // If it's already a date string, return it as is
              return timestamp;
            };

            setInitialValues({
              name: campaignData.name || { en: "", fr: "" },
              description: campaignData.description || { en: "", fr: "" },
              contactName: campaignData.contactName || "",
              contactEmail: campaignData.contactEmail || "",
              contactPhone: campaignData.contactPhone || "",
              couponBookPrice: campaignData.couponBookPrice || "",
              offeringURL: campaignData.offeringURL || "",
              endDate: formatDate(campaignData.endDate),
              redemptionStartDate: formatDate(campaignData.redemptionStartDate),
              redemptionEndDate: formatDate(campaignData.redemptionEndDate),
            });
          } else {
            toast({
              title: "Error",
              description: "Campaign not found",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            navigate(-1);
          }
        } catch (error) {
          console.error("Error fetching campaign:", error);
          toast({
            title: "Error",
            description: "Failed to load campaign data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchCampaign();
  }, [campaignId, isEditMode]);

  const handleSubmit = async (values, actions) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const targetOrgId = organizationId || user.organizationId;
      let newCampaignId = campaignId;

      if (isEditMode) {
        await axios.put(
          `${apiUrl}/campaigns/${campaignId}`,
          {
            ...values,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      } else {
        const response = await axios.post(
          `${apiUrl}/addCampaign`,
          {
            ...values,
            organizationId: targetOrgId,
            userId: user.uid,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userEmail: user.email,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        newCampaignId = response.data.campaignId;
      }

      actions.setSubmitting(false);
      toast({
        title: "Success",
        description: `Campaign ${
          isEditMode ? "updated" : "created"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/campaign/${newCampaignId}`);
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} campaign: `,
        error
      );
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: `Failed to ${
          isEditMode ? "update" : "create"
        } campaign. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDateChangeConfirmation = (values, actions) => {
    const originalStartDate = initialValues.redemptionStartDate;
    const originalEndDate = initialValues.redemptionEndDate;
    const newStartDate = values.redemptionStartDate;
    const newEndDate = values.redemptionEndDate;

    if (
      isEditMode &&
      (originalStartDate !== newStartDate || originalEndDate !== newEndDate)
    ) {
      setIsConfirmDialogOpen(true);
      setPendingSubmission({ values, actions });
    } else {
      handleSubmit(values, actions);
    }
  };

  const handleConfirmationSubmit = () => {
    if (hasConfirmedDateChange && pendingSubmission) {
      setIsConfirmDialogOpen(false);
      handleSubmit(pendingSubmission.values, pendingSubmission.actions);
      setHasConfirmedDateChange(false);
      setPendingSubmission(null);
    }
  };

  const renderStepContent = (step, formikProps) => {
    switch (step) {
      case 0:
        return (
          <VStack spacing={4}>
            <Field name="contactName">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactName && form.touched.contactName
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">Contact Name</FormLabel>
                  <Input {...field} placeholder="Enter contact name" />
                  <FormErrorMessage>{form.errors.contactName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="contactEmail">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactEmail && form.touched.contactEmail
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">Contact Email</FormLabel>
                  <Input
                    {...field}
                    type="email"
                    placeholder="Enter contact email"
                  />
                  <FormErrorMessage>
                    {form.errors.contactEmail}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="contactPhone">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactPhone && form.touched.contactPhone
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">Contact Phone</FormLabel>
                  <PhoneNumberInput field={field} />
                  <FormErrorMessage>
                    {form.errors.contactPhone}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      case 1:
        return (
          <VStack spacing={6} width="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 4, md: 6 }}
              width="100%"
            >
              <Field name="name.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name?.en && form.touched.name?.en}
                    isRequired
                    flex={1}
                  >
                    <FormLabel fontSize="sm">Campaign Name (English)</FormLabel>
                    <Input
                      {...field}
                      placeholder="Enter campaign name in English"
                    />
                    <FormErrorMessage>{form.errors.name?.en}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="name.fr">
                {({ field, form }) => (
                  <FormControl flex={1}>
                    <FormLabel fontSize="sm">Campaign Name (French)</FormLabel>
                    <Input
                      {...field}
                      placeholder="Enter campaign name in French (optional)"
                    />
                    <FormErrorMessage>{form.errors.name?.fr}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 4, md: 6 }}
              width="100%"
              alignItems="flex-start"
            >
              <Field name="description.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description?.en &&
                      form.touched.description?.en
                    }
                    isRequired
                    flex={1}
                  >
                    <FormLabel fontSize="sm">Description (English)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Enter campaign description in English"
                      minHeight="150px"
                    />
                    <FormErrorMessage>
                      {form.errors.description?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description.fr">
                {({ field, form }) => (
                  <FormControl flex={1}>
                    <FormLabel fontSize="sm">Description (French)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Enter campaign description in French (optional)"
                      minHeight="150px"
                    />
                    <FormErrorMessage>
                      {form.errors.description?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Field name="endDate">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.endDate && form.touched.endDate}
                  isRequired
                >
                  <HStack spacing={2}>
                    <FormLabel fontSize="sm">Campaign End Date</FormLabel>
                    <Tooltip
                      label="This is the last day customers can purchase coupon books. The redemption period (chosen in the next step) must extend at least one month beyond this date."
                      fontSize="sm"
                      placement="top"
                    >
                      <InfoOutlineIcon boxSize={4} color="gray.500" mb={2} />
                    </Tooltip>
                  </HStack>
                  <Input
                    {...field}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Select end date"
                  />

                  <FormErrorMessage>{form.errors.endDate}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      case 2:
        return (
          <VStack spacing={4}>
            <Field name="couponBookPrice">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.couponBookPrice && form.touched.couponBookPrice
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    Coupon Book Price (In CAD)
                  </FormLabel>
                  <Input
                    {...field}
                    type="number"
                    step="0.01"
                    placeholder="Enter coupon book price"
                  />
                  <FormErrorMessage>
                    {form.errors.couponBookPrice}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="redemptionStartDate">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.redemptionStartDate &&
                    form.touched.redemptionStartDate
                  }
                  isRequired
                >
                  <HStack spacing={2}>
                    <FormLabel fontSize="sm">
                      Coupon Redemption Start Date
                    </FormLabel>
                    <Tooltip
                      label="This is the first day customers can redeem their coupons."
                      fontSize="sm"
                      placement="top"
                    >
                      <InfoOutlineIcon boxSize={4} color="gray.500" mb={2} />
                    </Tooltip>
                  </HStack>
                  <Input
                    {...field}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Select redemption start date"
                  />
                  <FormErrorMessage>
                    {form.errors.redemptionStartDate}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="redemptionEndDate">
              {({ field, form }) => {
                // Calculate minimum end date (1 month after campaign end date)
                const campaignEndDate = form.values.endDate
                  ? new Date(form.values.endDate)
                  : null;
                const minEndDate = campaignEndDate
                  ? new Date(
                      campaignEndDate.setMonth(campaignEndDate.getMonth() + 1)
                    )
                  : null;

                return (
                  <FormControl
                    isInvalid={
                      form.errors.redemptionEndDate &&
                      form.touched.redemptionEndDate
                    }
                    isRequired
                  >
                    <HStack spacing={2}>
                      <FormLabel fontSize="sm">
                        Coupon Redemption End Date
                      </FormLabel>
                      <Tooltip
                        label="This is the last day customers can redeem their coupons. This date must extend at least 1 month past the campaign end date."
                        fontSize="sm"
                        placement="top"
                      >
                        <InfoOutlineIcon boxSize={4} color="gray.500" mb={2} />
                      </Tooltip>
                    </HStack>
                    <Input
                      {...field}
                      type="date"
                      min={
                        minEndDate ? minEndDate.toISOString().split("T")[0] : ""
                      }
                      placeholder="Select redemption end date"
                    />
                    <FormHelperText>
                      Must be at least 1 month after campaign end date
                    </FormHelperText>
                    <FormErrorMessage>
                      {form.errors.redemptionEndDate}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>

            <Field name="offeringURL">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.offeringURL && form.touched.offeringURL
                  }
                >
                  <FormLabel fontSize="sm">
                    Offering Page URL (Optional)
                  </FormLabel>
                  <Input
                    {...field}
                    type="url"
                    placeholder="Enter offering page URL"
                  />
                  <FormErrorMessage>{form.errors.offeringURL}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)"
        bg="primaryBackground"
        pt={{ base: "20px", md: "60px" }}
        px={{ base: 4, md: 6, lg: 8 }}
      >
        <VStack
          align="center"
          maxWidth="800px"
          margin="0 auto"
          pt={{ base: 4, md: 8 }}
        >
          <Heading
            as="h1"
            size={{ base: "md", md: "lg" }}
            textAlign="center"
            color="heading"
            pb={{ base: 2, md: 4 }}
          >
            {isEditMode ? "Edit Campaign" : "Create a Campaign"}
          </Heading>

          <Box
            width="100%"
            bg="secondaryBackground"
            p={{ base: 4, sm: 6, md: 8, lg: 12 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[activeStep]}
              onSubmit={async (values, actions) => {
                if (activeStep === steps.length - 1) {
                  await handleDateChangeConfirmation(values, actions);
                } else {
                  setActiveStep(activeStep + 1);
                  actions.setTouched({});
                  actions.setSubmitting(false);
                }
              }}
              enableReinitialize
            >
              {(props) => (
                <Form>
                  <VStack spacing={{ base: 6, md: 8 }} width="100%">
                    <FormStepper steps={steps} activeStep={activeStep} />

                    <Box width="100%" pt={{ base: 4, md: 10 }}>
                      {renderStepContent(activeStep, props)}
                    </Box>

                    <HStack
                      justify="space-between"
                      width="100%"
                      pt={{ base: 4, md: 6 }}
                      flexDir={{ base: "column", sm: "row" }}
                      spacing={{ base: 4, sm: 0 }}
                    >
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setActiveStep(Math.max(activeStep - 1, 0))
                        }
                        isDisabled={activeStep === 0}
                        width={{ base: "100%", sm: "auto" }}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        isLoading={props.isSubmitting}
                        width={{ base: "100%", sm: "auto" }}
                      >
                        {activeStep === steps.length - 1
                          ? isEditMode
                            ? "Update Campaign"
                            : "Create Campaign"
                          : "Next"}
                      </Button>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>

      <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setIsConfirmDialogOpen(false);
          setHasConfirmedDateChange(false);
          if (pendingSubmission) {
            pendingSubmission.actions.setSubmitting(false);
          }
          setPendingSubmission(null);
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Redemption Date Changes
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack align="start" spacing={4}>
                <Text>
                  Warning: You are about to change the redemption period for
                  this campaign. This will affect when customers can redeem
                  their coupons. Participating businesses must be notified and
                  agree to this change prior to the change being made.
                </Text>
                <Text fontWeight="bold">
                  Original period: {initialValues.redemptionStartDate} to{" "}
                  {initialValues.redemptionEndDate}
                </Text>
                <Text fontWeight="bold">
                  New period: {pendingSubmission?.values.redemptionStartDate} to{" "}
                  {pendingSubmission?.values.redemptionEndDate}
                </Text>
                <Checkbox
                  isChecked={hasConfirmedDateChange}
                  onChange={(e) => setHasConfirmedDateChange(e.target.checked)}
                >
                  I certify that I have notified all participating businesses of
                  this change and they have agreed to it.
                </Checkbox>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setIsConfirmDialogOpen(false);
                  setHasConfirmedDateChange(false);
                  if (pendingSubmission) {
                    pendingSubmission.actions.setSubmitting(false);
                  }
                  setPendingSubmission(null);
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmationSubmit}
                ml={3}
                isDisabled={!hasConfirmedDateChange}
              >
                Confirm Changes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CampaignForm;
