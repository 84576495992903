import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  FormErrorMessage,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Image,
  Center,
  Flex,
  Textarea,
  Icon,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";
import Navbar from "../components/Navbar";
import { FaStore, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const ValidateCoupon = () => {
  const { language, setLanguage, translate } = useLanguage();
  const [alertState, setAlertState] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [validationState, setValidationState] = useState("initial"); // 'initial', 'validated', 'redeemed'
  const [redemptionDate, setRedemptionDate] = useState(null);
  const [redemptionNotes, setRedemptionNotes] = useState("");
  const [offerDetails, setOfferDetails] = useState(null);
  const [customerCode, setCustomerCode] = useState("");
  const [redemptionError, setRedemptionError] = useState(null);
  const [validationMessageCode, setValidationMessageCode] = useState(null);
  const [redemptionMessageCode, setRedemptionMessageCode] = useState(null);

  const navigate = useNavigate();
  const { businessCode: urlBusinessCode, lang } = useParams();

  useEffect(() => {
    if (lang === "fr") {
      setLanguage("fr");
    }
  }, [lang, setLanguage]);

  // Fetch business data directly from Firestore
  useEffect(() => {
    const fetchBusinessData = async () => {
      if (urlBusinessCode) {
        try {
          const businessesRef = collection(db, "businesses");
          const q = query(
            businessesRef,
            where("passcode", "==", urlBusinessCode)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const businessDoc = querySnapshot.docs[0];
            const data = businessDoc.data();
            setBusinessData(data);

            try {
              const logoRef = ref(storage, data.logoUrl);
              const url = await getDownloadURL(logoRef);
              setLogoUrl(url);
            } catch (error) {
              console.error(
                `Error fetching logo for business ${data.businessId}:`,
                error
              );
            }
          }
        } catch (error) {
          console.error("Error fetching business data:", error);
        }
      }
    };

    fetchBusinessData();
  }, [urlBusinessCode]);

  // Update displayed offer details when language changes
  useEffect(() => {
    if (offerDetails) {
      setAlertState((prev) => ({
        ...prev,
        description: `${offerDetails[language]}`,
      }));
    }
  }, [language, offerDetails, translate]);

  const validationSchema = Yup.object().shape({
    customerCode: Yup.string().required(
      translate("validateCoupon.validationErrors.customerCodeRequired")
    ),
    passcode: Yup.string().required(
      translate("validateCoupon.validationErrors.passcodeRequired")
    ),
  });

  const handleSubmit = async (values, actions) => {
    try {
      const response = await axios.post(`${apiUrl}/validateCoupon`, values);
      if (response.data.valid) {
        setValidationState("validated");
        setOfferDetails(response.data.offerDetails);
        setCustomerCode(values.customerCode);
        setValidationMessageCode(response.data.messageCode);
        setAlertState({
          status: "success",
          title: translate("validateCoupon.alertValidTitle"),
          description: response.data.offerDetails[language],
          termsAndExclusions: response.data.termsAndExclusions,
        });
      } else {
        setValidationMessageCode(response.data.messageCode);
        setAlertState({
          status: "error",
          title: translate("validateCoupon.alertInvalidTitle"),
          description: translate(
            `validateCoupon.alertMessages.${response.data.messageCode}`
          ),
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setValidationMessageCode("6");
      setAlertState({
        status: "error",
        title: translate("validateCoupon.alertInvalidTitle"),
        description: translate("validateCoupon.alertMessages.6"),
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  // Update alert messages when language changes
  useEffect(() => {
    if (alertState) {
      setAlertState((prev) => ({
        ...prev,
        title:
          prev.status === "success"
            ? translate("validateCoupon.alertValidTitle")
            : translate("validateCoupon.alertInvalidTitle"),
        description:
          prev.status === "success"
            ? offerDetails?.[language]
            : translate(
                `validateCoupon.alertMessages.${validationMessageCode}`
              ),
        termsAndExclusions: prev.termsAndExclusions,
      }));
    }
  }, [language, validationMessageCode, offerDetails, translate]);

  const handleRedeemCoupon = async () => {
    try {
      setRedemptionMessageCode(null);
      console.log("Attempting to redeem coupon...");
      const response = await axios.post(`${apiUrl}/redeemCoupon`, {
        customerCode: customerCode,
        passcode: urlBusinessCode,
        notes: redemptionNotes,
      });

      if (response.data.success) {
        setValidationState("redeemed");
        setRedemptionDate(new Date());
        setAlertState(null);
      } else {
        setAlertState(null);
        setRedemptionMessageCode(response.data.messageCode);
      }
    } catch (error) {
      console.error("Error redeeming coupon:", error);
      setAlertState(null);
      setRedemptionMessageCode("6");
    }
  };

  return (
    <>
      <Navbar external={true} />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack align="center" maxWidth="500px" margin="0 auto" pt={8}>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            {logoUrl && (
              <Center mb={6}>
                <Image
                  src={logoUrl}
                  alt={businessData.name.en}
                  maxH="100px"
                  objectFit="contain"
                />
              </Center>
            )}
            <Heading as="h1" size="lg" textAlign="center" color="heading">
              {translate("validateCoupon.heading")}
            </Heading>
            {businessData && urlBusinessCode && (
              <Heading
                as="h2"
                size="md"
                textAlign="center"
                color="heading"
                fontWeight="medium"
              >
                {businessData.name[language] || businessData.name.en}
              </Heading>
            )}

            <Box mb={8}>
              {/* <Text textAlign="center" color="text" pb={4}>
            {translate("validateCoupon.description")}
          </Text> */}
            </Box>
            {validationState === "initial" && alertState && (
              <Alert
                status={alertState.status}
                mb={4}
                borderRadius="md"
                textAlign="left"
              >
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>{alertState.title}</AlertTitle>
                  <AlertDescription>{alertState.description}</AlertDescription>
                </Box>
              </Alert>
            )}

            {validationState === "initial" && (
              <Formik
                initialValues={{
                  customerCode: "",
                  passcode: urlBusinessCode || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form>
                    <VStack spacing={4}>
                      <Field name="customerCode">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.customerCode &&
                              form.touched.customerCode
                            }
                          >
                            <FormLabel htmlFor="customerCode">
                              {translate("validateCoupon.customerCode")}
                            </FormLabel>
                            <Input
                              {...field}
                              id="customerCode"
                              placeholder={translate(
                                "validateCoupon.customerCodePlaceholder"
                              )}
                            />
                            <FormErrorMessage>
                              {form.errors.customerCode}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      {!urlBusinessCode && (
                        <Field name="passcode">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.passcode && form.touched.passcode
                              }
                            >
                              <FormLabel htmlFor="passcode">
                                {translate("validateCoupon.businessPasscode")}
                              </FormLabel>
                              <Input
                                {...field}
                                id="passcode"
                                type="password"
                                placeholder={translate(
                                  "validateCoupon.businessPasscodePlaceholder"
                                )}
                              />
                              <FormErrorMessage>
                                {form.errors.passcode}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      <Button
                        mt={4}
                        variant="primary"
                        isLoading={props.isSubmitting}
                        type="submit"
                        width="100%"
                      >
                        {translate("validateCoupon.validateButton")}
                      </Button>
                    </VStack>
                  </Form>
                )}
              </Formik>
            )}

            {validationState === "validated" && (
              <VStack spacing={4} align="stretch">
                {redemptionMessageCode ? (
                  <VStack spacing={4} align="center" mb={4}>
                    <Icon
                      as={FaTimesCircle}
                      w={16}
                      h={16}
                      color="red.500"
                      animation="scale 0.5s ease-in-out"
                    />
                    <Text fontSize="xl" fontWeight="bold" color="gray.700">
                      {translate("validateCoupon.redemptionError")}
                    </Text>
                    <Text fontSize="lg" color="gray.600" textAlign="center">
                      {translate(
                        `validateCoupon.alertMessages.${redemptionMessageCode}`
                      )}
                    </Text>
                  </VStack>
                ) : (
                  <>
                    <Alert
                      status="success"
                      mb={4}
                      borderRadius="md"
                      textAlign="left"
                    >
                      <AlertIcon />
                      <Box flex="1">
                        <AlertTitle>
                          {translate("validateCoupon.alertValidTitle")}
                        </AlertTitle>
                        <AlertDescription>
                          {offerDetails[language]}
                          {alertState?.termsAndExclusions && (
                            <Text mt={2} fontSize="sm" color="gray.600">
                              {alertState.termsAndExclusions[language]}
                            </Text>
                          )}
                        </AlertDescription>
                      </Box>
                    </Alert>

                    <FormControl>
                      <FormLabel>
                        {translate("validateCoupon.redemptionNotes")}
                      </FormLabel>
                      <Textarea
                        value={redemptionNotes}
                        onChange={(e) => setRedemptionNotes(e.target.value)}
                        placeholder={translate(
                          "validateCoupon.redemptionNotesPlaceholder"
                        )}
                      />
                    </FormControl>

                    <Button
                      variant="primary"
                      width="100%"
                      onClick={handleRedeemCoupon}
                    >
                      {translate("validateCoupon.redeemButton")}
                    </Button>
                  </>
                )}
              </VStack>
            )}

            {validationState === "redeemed" && (
              <VStack spacing={6} align="center">
                <Icon
                  as={FaCheckCircle}
                  w={16}
                  h={16}
                  color="primaryHighlight"
                  animation="scale 0.5s ease-in-out"
                />
                <Text fontSize="xl" fontWeight="bold" color="gray.700">
                  {translate("validateCoupon.redemptionComplete")}
                </Text>
                <Text color="gray.700">
                  <Text as="span" fontWeight="bold">
                    {translate("validateCoupon.redeemedOn")}:{" "}
                  </Text>
                  {redemptionDate.toLocaleDateString()}
                </Text>
                {redemptionNotes && (
                  <>
                    <Text color="gray.700">
                      <Text as="span" fontWeight="bold">
                        {translate("validateCoupon.notes")}:{" "}
                      </Text>
                      {redemptionNotes}
                    </Text>
                  </>
                )}
                <Button
                  variant="primary"
                  width="100%"
                  onClick={() => window.location.reload()}
                >
                  {translate("common.home")}
                </Button>
              </VStack>
            )}
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default ValidateCoupon;
