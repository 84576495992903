import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Box, VStack } from "@chakra-ui/react";
import { useLanguage } from "../contexts/LanguageContext";
import {
  getValidationSchemas,
  getInitialValues,
  handleLogoChange,
} from "../forms/businessFormConfig";
import BusinessFormContent from "./BusinessFormContent";

const BusinessFormWrapper = ({
  initialData,
  onSubmit,
  isEditMode = false,
  renderHeader,
  isSubmitting = false,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(initialData?.logoUrl || null);
  const { translate } = useLanguage();

  // If in edit mode and there's an existing logo URL, we want to keep track of whether
  // the logo has been changed
  const [logoChanged, setLogoChanged] = useState(false);

  const validationSchemas = getValidationSchemas(translate);
  const initialValues = getInitialValues(initialData);

  // Update logo preview when initialData changes
  useEffect(() => {
    if (initialData?.logoUrl) {
      setLogoPreview(initialData.logoUrl);
    }
  }, [initialData]);

  const handleLogoChangeWrapper = (event) => {
    setLogoChanged(true);
    handleLogoChange(setLogo, setLogoPreview)(event);
  };

  return (
    <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
      <VStack align="center" maxWidth="800px" margin="0 auto" pt={8}>
        {renderHeader && renderHeader()}

        <Box
          width="100%"
          bg="secondaryBackground"
          p={{ base: 4, sm: 6, md: 8, lg: 12 }}
          borderRadius="xl"
          boxShadow="md"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[activeStep]}
            onSubmit={async (values, actions) => {
              if (activeStep === validationSchemas.length - 1) {
                // Only pass the logo if it's been changed in edit mode or if it's a new business
                const logoToSubmit = logoChanged ? logo : null;
                await onSubmit(values, actions, { logo: logoToSubmit });
              } else {
                setActiveStep(activeStep + 1);
                actions.setTouched({});
                actions.setSubmitting(false);
              }
            }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <BusinessFormContent
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleLogoChange={handleLogoChangeWrapper}
                logoPreview={logoPreview}
                setLogoPreview={setLogoPreview}
                isEditMode={isEditMode}
                isSubmitting={isSubmitting || formikProps.isSubmitting}
              />
            )}
          </Formik>
        </Box>
      </VStack>
    </Box>
  );
};

export default BusinessFormWrapper;
