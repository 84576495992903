import React from "react";
import { Box, Button, HStack } from "@chakra-ui/react";
import { FaPrint, FaHome } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { useLanguage } from "../contexts/LanguageContext";

const A4Page = ({ content }) => {
  const { user } = UserAuth();
  const { translate } = useLanguage();

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {/* Screen View */}
      <Box minH="100vh" bg="#f7fafc" p={8}>
        {/* Print Button and Home Button */}
        <Box
          display={{ base: "flex", print: "none" }}
          mb={8}
          mt={4}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <HStack spacing={4} justify="center">
            <Button
              leftIcon={<FaPrint />}
              variant="primary"
              onClick={handlePrint}
              size="md"
            >
              {translate("a4Page.printPage")}
            </Button>

            {user && (
              <Button
                as={RouterLink}
                to="/"
                leftIcon={<FaHome />}
                variant="secondary"
                size="md"
              >
                {translate("common.home")}
              </Button>
            )}
          </HStack>
        </Box>

        {/* A4 Paper Preview */}
        <Box
          id="printable-content"
          bg="white"
          w="210mm"
          minH="297mm"
          mx="auto"
          p="20mm"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)"
          position="relative"
          transition="box-shadow 0.3s ease"
        >
          {content}
        </Box>
      </Box>

      <style jsx global>{`
        @media print {
          @page {
            size: A4;
            margin: 0;
          }

          html,
          body {
            height: 297mm;
            overflow: hidden;
          }

          body * {
            visibility: hidden;
          }

          #printable-content,
          #printable-content * {
            visibility: visible !important;
            position: relative !important;
          }

          #printable-content {
            position: absolute !important;
            left: 0;
            top: 0;
            width: 210mm;
            height: 297mm;
            padding: 20mm;
            margin: 0;
            background: white;
            box-shadow: none;
            page-break-after: avoid;
            overflow: hidden;
          }
        }
      `}</style>
    </>
  );
};

export default A4Page;
