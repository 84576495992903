import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Heading, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Navbar from "../components/Navbar";
import { apiUrl } from "../config";
import { UserAuth } from "../contexts/AuthContext";
import BusinessFormWrapper from "../components/BusinessFormWrapper";

const BusinessForm = () => {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [businessData, setBusinessData] = useState(null);
  const { user } = UserAuth();
  const navigate = useNavigate();

  const campaignId = searchParams.get("campaignId");
  const businessId = searchParams.get("businessId");
  const isEditMode = !!businessId;
  const [businessCampaignId, setBusinessCampaignId] = useState();

  useEffect(() => {
    const fetchBusiness = async () => {
      if (isEditMode && businessId) {
        try {
          const businessDoc = await getDoc(doc(db, "businesses", businessId));
          if (businessDoc.exists()) {
            const data = businessDoc.data();
            setBusinessData({
              ...data,
              confirmPasscode: data.passcode,
            });
            setBusinessCampaignId(data.campaignId);
          } else {
            toast({
              title: "Error",
              description: "Business not found",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            navigate(-1);
          }
        } catch (error) {
          console.error("Error fetching business:", error);
          toast({
            title: "Error",
            description: "Failed to fetch business data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else if (campaignId) {
        setBusinessCampaignId(campaignId);
      }
    };

    fetchBusiness();
  }, [businessId, isEditMode, campaignId, navigate, toast]);

  const handleSubmit = async (values, actions, { logo }) => {
    try {
      const formData = new FormData();

      // Add all the nested objects as stringified JSON
      formData.append("name", JSON.stringify(values.name));
      formData.append("category", JSON.stringify(values.category));
      formData.append("offerTitle", JSON.stringify(values.offerTitle));
      formData.append(
        "offerDescription",
        JSON.stringify(values.offerDescription)
      );
      formData.append(
        "termsAndExclusions",
        JSON.stringify(values.termsAndExclusions)
      );
      formData.append("maxValue", values.maxValue);

      // Add regular fields
      formData.append("websiteUrl", values.websiteUrl || "");
      formData.append("googleProfileUrl", values.googleProfileUrl || "");
      formData.append("email", values.email);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("passcode", values.passcode);

      // Add required IDs
      formData.append("agentId", user.uid);
      formData.append("campaignId", businessCampaignId);

      // Add logo if exists
      if (logo) {
        formData.append("logo", logo);
      }

      const idToken = sessionStorage.getItem("idToken");
      let response = null;
      let businessPasscode = null;

      if (isEditMode) {
        await axios.put(`${apiUrl}/businesses/${businessId}`, formData, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        response = await axios.post(`${apiUrl}/addBusiness`, formData, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
        businessPasscode = response.data.passcode;
      }

      toast({
        title: "Success",
        description: `Business ${
          isEditMode ? "updated" : "created"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (!isEditMode) {
        navigate(`/add-business-success-internal/${businessPasscode}`);
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting business:", error);
      toast({
        title: "Error",
        description: `Failed to ${isEditMode ? "update" : "create"} business`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    actions.setSubmitting(false);
  };

  const renderHeader = () => (
    <>
      <Heading size="lg">
        {isEditMode ? "Edit Business" : "Add a Business"}
      </Heading>
      <Text mb={4} color="text">
        {isEditMode
          ? "Edit the details of the business or it's coupon offering"
          : "Complete this form to add a business to your campaign's coupon book."}
      </Text>
    </>
  );

  return (
    <>
      <Navbar />
      <BusinessFormWrapper
        initialData={businessData}
        onSubmit={handleSubmit}
        isEditMode={isEditMode}
        renderHeader={renderHeader}
      />
    </>
  );
};

export default BusinessForm;
