import React from "react";
import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";
import { useLanguage } from "../contexts/LanguageContext";

const FormStepper = ({ steps, activeStep }) => {
  const { translate } = useLanguage();

  return (
    <Box width="100%" overflowX={{ base: "auto", md: "visible" }}>
      <Stepper
        index={activeStep}
        width="100%"
        colorScheme="blue"
        size={{ base: "sm", md: "md" }}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator
              sx={{
                "[data-status=complete] &": {
                  background: "primaryHighlight",
                  borderColor: "primaryHighlight",
                },
                "[data-status=active] &": {
                  background: "primaryHighlight",
                  borderColor: "primaryHighlight",
                },
                "[data-status=incomplete] &": {
                  background: "white",
                  borderColor: "lightGray",
                },
              }}
            >
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber color="black" />}
              />
            </StepIndicator>
            <Box flexShrink="0" display={{ base: "none", md: "block" }}>
              <StepTitle>{translate(step.title)}</StepTitle>
            </Box>
            <StepSeparator
              _horizontal={{
                "[data-status=complete] &": {
                  background: "primaryHighlight",
                },
              }}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default FormStepper;
