import React from "react";
import AddBusinessSuccess from "./AddBusinessSuccess";
import { useParams } from "react-router-dom";

const AddBusinessSuccessInternal = () => {
  const { businessId } = useParams();

  return (
    <AddBusinessSuccess
      businessId={businessId}
      title="Business Added Successfully!"
      subtitle="The business has been added to the campaign."
      nextSteps={[
        "Print or save the instructions for validating coupons for businesses",
        "Go through the instructions with the business",
      ]}
      viewInstructionsText="View Business Instructions"
      homeText="Home"
    />
  );
};

export default AddBusinessSuccessInternal;
