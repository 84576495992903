import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBNiNbAMALmQ1o2urvTdcQYkHUJbjCZqJU",
  authDomain: "auth.coupal.io",
  projectId: "digital-coupon-book-cf832",
  storageBucket: "digital-coupon-book-cf832.appspot.com",
  messagingSenderId: "226936392588",
  appId: "1:226936392588:web:46b3027642686eb37c64a9",
  measurementId: "G-H8M9C9XG6D",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { analytics, db, auth, storage };
